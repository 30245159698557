@import 'styles/mixins';

$arrow-offset: 8px;

.card:hover {
  @include breakpoint-sm {
    border-color: #fff !important;
  }
}

@mixin arrow-hover {
  &:hover {
    transition: all 0.3s ease-in-out;
    scale: 1.1;
  }
}

.arrow-left {
  right: $arrow-offset;

  @include arrow-hover;
}

.arrow-right {
  left: $arrow-offset;

  @include arrow-hover;
}

.gradient-left {
  left: $arrow-offset;
}

.gradient-right {
  right: $arrow-offset;
}
